body {
  margin: 0;
}

.ant-modal.ven-borderless > .ant-modal-content {
  padding: 0;
}

.ven-content-text {
  max-width: 800px;
  margin: auto;
  padding: 16px;
}

.ven-menu {
  height: 100vh;
  text-align: right;
  background-color: #333;
  border-right: 2px solid #f15421;
  padding-right: 32px;
}

.ven-menu * > a, .ven-menu * > .ant-typography {
  color: #fff;
  transition: all .3s;
}

.ven-menu * > a:hover {
  color: #f15421;
}

/*# sourceMappingURL=index.7240e13e.css.map */
