body {
  margin: 0;
}

.ant-modal.ven-borderless > .ant-modal-content {
  padding: 0;
}

.ven-content-text {
  max-width: 800px;
  margin: auto;
  padding: 16px;
}

.ven-menu {
  background-color: #333;
  height: 100vh;
  padding-right: 32px;
  text-align: right;
  border-right: 2px solid rgb(241,84,33);
}

.ven-menu * > a, .ven-menu * > .ant-typography {
  color: #fff;
  transition: all 0.3s ease;
}

.ven-menu * > a:hover {
  color: rgb(241,84,33);
}
